@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url('./fonts/OpenSans-Regular.woff') format("woff"),
    url('./fonts/OpenSans-Regular.woff') format("woff");
  font-weight: normal;
  font-size: normal;
}

@font-face {
  font-family: 'Futura';
  src: local('Futura'), url('./fonts/FuturaBT-Medium.woff') format("woff"),
    url('./fonts/FuturaBT-Medium.woff2') format("woff2");
  font-weight: normal;
  font-size: normal;
}

@font-face {
  font-family: 'Futura Bold';
  src: local('Futura Bold'), url('./fonts/Futura-Bold.woff') format("woff");
  font-weight: normal;
  font-size: normal;
}

@font-face {
  font-family: 'Futura LT Bold';
  src: local('Futura LT Bold'), url('./fonts/FuturaLT-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Futura LT Regular';
  src: local('Futura LT Regular'), url('./fonts/FuturaLT.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Shell Bold';
  src: local('Shell Bold'), url('./fonts/ShellFont/ShellBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Shell Book';
  src: local('Shell Book'), url('./fonts/ShellFont/ShellBook.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Shell Book Italic';
  src: local('Shell Book Italic'), url('./fonts/ShellFont/ShellBookItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Shell Condensed Bold';
  src: local('Shell Condensed Bold'), url('./fonts/ShellFont/ShellCondensedBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Shell Condensed Medium';
  src: local('Shell Condensed Medium'), url('./fonts/ShellFont/ShellCondensedMedium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Shell Condensed Light';
  src: local('Shell Condensed Light'), url('./fonts/ShellFont/ShellCondensedLight.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Shell Heavy';
  src: local('Shell Heavy'), url('./fonts/ShellFont/ShellHeavy.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Shell Light';
  src: local('Shell Light'), url('./fonts/ShellFont/ShellLight.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Shell Light Italic';
  src: local('Shell Light Italic'), url('./fonts/ShellFont/ShellLightItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Shell Medium';
  src: local('Shell Medium'), url('./fonts/ShellFont/ShellMedium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Shell Medium Italic';
  src: local('Shell Medium Italic'), url('./fonts/ShellFont/ShellMediumItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: local('Helvetica Neue light'), url('./fonts/HelveticaNeue/Helvetica.ttc') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: local('Helvetica Neue bold'), url('./fonts/HelveticaNeue/HelveticaNeueLTPro-Bd.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue extended';
  src: local('Helvetica Neue extended'), url('./fonts/HelveticaNeue/HelveticaNeueLTPro-LtEx.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}